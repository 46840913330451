import React, { useMemo } from 'react';

import TableContainer from '../../../Components/Gallium/TableContainerNew';

import {
    Card,
    CardBody,
    Col,
    Row,
} from "reactstrap"

import Loader from 'Components/Gallium/Loader';
import ErrorNotice from 'Components/Gallium/ErrorNotice';
import { useListBackupProfiles } from 'GalliumAPIHooks/Backup/BackupHooks';
import { Link } from 'react-router-dom';
import { LocalDataTimeString } from 'Components/Gallium/GalliumTimeHelpers';

const BackupListComponent = () => {
    const { data, error, isLoading } = useListBackupProfiles()

    const columns = useMemo(
        () => [
            {
                header: "Name",
                accessorKey: "name",
                enableColumnFilter: false,
                className: "col-2",
                cell: (cellProps) => {
                    return (
                        <React.Fragment>
                            <Link to={`/backups/${cellProps.row.original.slug}`}>
                                <span className="fw-medium">{cellProps.getValue()}</span>
                            </Link>
                        </React.Fragment>
                    );
                },
            },
            {
                header: "Destination",
                accessorKey: "targetHost",
                enableColumnFilter: false,
                className: "col-3",
                cell: (cellProps) => {
                    return (
                        <React.Fragment>
                            <span className="fw-medium">{cellProps.getValue().name}</span>
                        </React.Fragment>
                    );
                },
            },
            {
                header: "Retention",
                accessorKey: "expiryDays",
                enableColumnFilter: false,
                className: "col-1 text-center",
                cell: (cellProps) => {
                    return (
                        <React.Fragment>
                            <span className="fw-medium">{cellProps.getValue()}{" Days"}</span>
                        </React.Fragment>
                    );
                },
            },
            {
                header: "Virtual Machines",
                accessorKey: "backupSets",
                enableColumnFilter: false,
                className: "col-1 text-center",
                cell: (cellProps) => {
                    return (
                        <React.Fragment>
                            <span className="fw-medium">{cellProps.getValue().length}</span>
                        </React.Fragment>
                    );
                },
            },
            {
                header: "Next Run",
                accessorKey: "nextRunTime",
                enableColumnFilter: false,
                className: "col-5",
                cell: (cellProps) => {
                    return (
                        <React.Fragment>
                            <span className="fw-medium">{LocalDataTimeString(cellProps.getValue())}</span>
                        </React.Fragment>
                    );
                },
            }
        ],[]
    );

    const sorting = useMemo(
        () => [
            {
                id: 'name',
                desc: true, 
            },
        ],[]
    );

    return (
        <React.Fragment>

            {isLoading ? (<Loader />) : error ? (<ErrorNotice />) : (
                <Row>
                    <Col lg={12}>
                        <Card id="BackupList">
                            <CardBody className="pt-0">
                                <TableContainer
                                    columns={columns}
                                    data={(data.profiles || [])}
                                    isGlobalFilter={true}
                                    customPageSize={20}
                                    sorting={sorting}
                                    divClass="table-responsive table-card mb-3"
                                    tableClass="align-middle table-nowrap mb-0"
                                    theadClass="table-light table-nowrap"
                                    thClass="table-light text-muted"
                                    SearchPlaceholder='Search for a Backup Profile'
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            )}            
                
        </React.Fragment>
    );
};

export default BackupListComponent;