import React, { useState } from "react";
import { Row, Col, CardBody, Card, Container, Label } from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { Field, FormikProvider, useFormik } from "formik";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Link, useNavigate, useSearchParams } from "react-router-dom";

import { usePostHog } from 'posthog-js/react'

//import images 
import logoLight from "../../assets/images/logo-light.png";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { GalliumInput, GalliumSubmitButton } from "Components/Gallium/GalliumForms";
import ErrorAlert from "Components/Gallium/ErrorHelper";
import { useRegister } from "GalliumAPIHooks/User/UserHooks";
import { GalliumApiErrorResponse, GalliumApiSuccessResponse, GalliumLoginRequest, GalliumLoginResponse, GalliumRegisterRequest } from "generated";
import { setAuthorization } from "helpers/api_helper";
import { useLoginRequest } from "Components/Hooks/LoginHooks";

const Register = () => {
    const navigate = useNavigate();
    const posthog = usePostHog()

    const {trigger, isMutating} = useRegister()
    const [searchParams] = useSearchParams();

    const [errorObject, setErrorObject] = useState<GalliumApiErrorResponse | null>(null)

    const { trigger: loginTrigger } = useLoginRequest()


    const handleLoginRequest = (values: GalliumLoginRequest) => {
        const options = {
            onError() {
                toast.warning("Login Failed")
            },
            onSuccess(data: GalliumLoginResponse) {
                setAuthorization(data.accessToken)
                localStorage.setItem("authUser", JSON.stringify(data))
                posthog?.identify(data.user.email,{name: data.user.name })
                navigate("./hypervisors")
            },
            revalidate: false
        }
        loginTrigger(values, options);
    }

    const handleRegisterFail = (error: GalliumApiErrorResponse) => {
        registerFormik.setErrors(error.errors || {})
        setErrorObject(error || {})
    }

    const handleRegisterSuccess = (data: GalliumApiSuccessResponse) => {
        toast.success("Organisation Created")
        const loginObj = {
            email: registerFormik.values.user.email,
            password: registerFormik.values.user.password
        }
        handleLoginRequest(loginObj)
    }

    const handleRegisterRequest = (values: GalliumRegisterRequest) => {
        const options = {
            onError(error: object) {
                handleRegisterFail(error)
            },
            onSuccess(data: GalliumApiSuccessResponse) {
                handleRegisterSuccess(data)
            },
            revalidate: false
        }
        setErrorObject(undefined)
        trigger(values, options);
    }

    const registerFormik = useFormik({
        initialValues: {
            user: {
                email: searchParams.get("email") || '',
                name: '',
                password: '',
            },
            orgName: '',
            slug: '',
        },
        validationSchema: Yup.object({
            user: Yup.object({
                email: Yup.string().email('Invalid email address').required("Please Enter Your Email"),
                name: Yup.string().required("Please Enter Your Username"),
                password: Yup.string().required("Please Enter Your Password"),
            }),
            orgName: Yup.string().required("Please provide and organisation name"),
            slug: Yup.string()
                .max(32, "Organization name must be at most 32 characters long")
                .matches(/^[^\s]+$/, "Organization name cannot contain spaces")
                .required("Please provide and organisation short name"),
        }),
        onSubmit: (values) => {
            handleRegisterRequest(values);
        }
    });    

    document.title = "Sign Up | Gallium";
    return (
        <React.Fragment>
            <ParticlesAuth>
                <div className="auth-page-content">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center mt-sm-5 mb-4 text-white-50">
                                    <div>
                                        <Link to="/" className="d-inline-block auth-logo">
                                            <img src={logoLight} alt="" height="45" />
                                        </Link>
                                    </div>
                                    <p className="mt-3 fs-15 fw-medium">Our Cloud - Your Edge</p>
                                </div>
                            </Col>
                        </Row>

                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="mt-4">

                                    <CardBody className="p-4">
                                        <div className="text-center mt-2">
                                            <h5 className="text-primary">Create New Gallium Organisation</h5>
                                            <p className="text-muted">Get started for free</p>
                                        </div>

                                        <ErrorAlert errorObj={errorObject} />

                                        <div className="p-2 mt-4">
                                            <FormikProvider value={registerFormik}>

                                                <div className="mb-3">
                                                    <Label htmlFor="display_nameField" className="form-label">Your Name <span className="text-danger">*</span></Label>
                                                    <Field
                                                        id="display_nameField"
                                                        name="user.name"
                                                        type="text"
                                                        placeholder="Enter your name"
                                                        component={GalliumInput}
                                                    />


                                                </div>
                                                <div className="mb-3">
                                                    <Label htmlFor="emailField" className="form-label">Email <span className="text-danger">*</span></Label>
                                                    <Field
                                                        id="emailField"
                                                        name="user.email"
                                                        placeholder="Enter email address"
                                                        type="email"
                                                        component={GalliumInput}
                                                    />
                                                </div>

                                                <div className="mb-3">
                                                    <Label htmlFor="passwordField" className="form-label">Password <span className="text-danger">*</span></Label>
                                                    <Field
                                                        id="passwordField"
                                                        name="user.password"
                                                        type="password"
                                                        placeholder="Enter Password"
                                                        component={GalliumInput}
                                                    />
                                                </div>

                                                <div className="mb-3">
                                                    <Label htmlFor="orgNameField" className="form-label">Your Organisation <span className="text-danger">*</span></Label>
                                                    <Field
                                                        id="orgNameField"
                                                        name="orgName"
                                                        type="text"
                                                        placeholder="Enter an organisation name"
                                                        component={GalliumInput}
                                                    />
                                                </div>

                                                <div className="mb-3">
                                                    <Label htmlFor="slugField" className="form-label">Organisation Short Name <span className="text-danger">*</span></Label>
                                                    <Field
                                                        id="slugField"
                                                        name="slug"
                                                        type="text"
                                                        placeholder="Must be unique and contain no spaces"
                                                        component={GalliumInput}
                                                    />
                                                </div>

                                                {/* <div className="mb-4"> */}
                                                {/* <p className="mb-0 fs-12 text-muted fst-italic">By registering you agree to the Velzon
                                                        <Link to="#" className="text-primary text-decoration-underline fst-normal fw-medium">Terms of Use</Link></p>
                                                </div> */}

                                                <div className="mt-4 text-end">
                                                    <GalliumSubmitButton formik={registerFormik} color="success" spinner={isMutating}>
                                                        Sign In
                                                    </GalliumSubmitButton>
                                                </div>
                                            </FormikProvider>
                                        </div>
                                    </CardBody>
                                </Card>
                                <div className="mt-4 text-center">
                                    <p className="mb-0">Already have an account ? <Link to="/login" className="fw-semibold text-primary text-decoration-underline"> Signin </Link> </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </ParticlesAuth>
        </React.Fragment>
    );
};

export default Register;

