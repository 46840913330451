import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import * as Yup from "yup";

import { useFormik, FormikProvider } from "formik";

import NetworkInterfaces from './NetworkInterfaces';
import Disks from './Disks';
import CPUMemory from './CPUMemory';
import Basics from './Basics';
import Authentication from './Authentication';
import ErrorAlert from 'Components/Gallium/ErrorHelper';
import Cloudinit from './Cloudinit';
import { useNewVm } from 'GalliumAPIHooks/Host/HostHooks';
import { GalliumSubmitButton } from 'Components/Gallium/GalliumForms';
import { CreateVmRequest, GalliumApiErrorResponse, GalliumApiSuccessResponse, HostDetail, Template } from 'generated';
import Startup from './Startup';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-d2e55409b9/icons';


interface CreateVMFormProps {
    hypervisor: HostDetail
    template: Template
}

const CreateVMForm: React.FC<CreateVMFormProps> = ({hypervisor, template}) => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const { trigger, isMutating } = useNewVm(hypervisor.slug);
    const [errorObject, setErrorObject] = useState<GalliumApiErrorResponse | null>(null)

    const handleCreateVirtualMachineFail = (error: GalliumApiErrorResponse) => {
        setErrorObject(error || {})
    }

    const handleCreateVirtualMachineSuccess = (response: GalliumApiSuccessResponse) => {
        navigate(`/vm/${response.command.vmSlug}`)
    }

    const handleCreateVirtualMachineRequest = (requestObj: CreateVmRequest) => {
        const options = {
            onError(error: GalliumApiErrorResponse) {
                handleCreateVirtualMachineFail(error)
            },
            onSuccess(response: GalliumApiSuccessResponse) {
                handleCreateVirtualMachineSuccess(response)
            }
        }
        setErrorObject(null)
        
        trigger(requestObj, options);
    }

    const initialDisks = template.disks.map(disk => ({
        ...disk, 
        size: disk.minSizeMb || 1,
    })) || []
    // Formik
    const addVirtualMachineFormik = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            name: '',
            templateSlug: template?.slug,
            memory: template?.minMemoryMb,
            cpus: 1,
            description: "",
            sshAutoAdd: true,
            storagePool: '',
            networkInterfaces: [hypervisor?.defaultNetworkSlug],
            disks: initialDisks,
            cloudInitUserData: '',
            autoStart: false,
            createStarted: true
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Please Enter Name"),
            autoAdd: Yup.boolean(),
            memory: Yup.number()
                .min(template?.minMemoryMb, "Memory allocation too low")
                .max(hypervisor?.memTotalMb, "Memory allocation exceeds Hypervisor capacity"),
            networkInterfaces: Yup.array()
                .of(Yup.string().required("Interface must be bound to a Network"))
                .test(
                    'unique-network-interfaces', 
                    'Only one Interface per Network is allowed', 
                    (networkInterfaces) => {
                        if (!networkInterfaces) return true; // If undefined or null, skip this test
                        const unique = new Set(networkInterfaces);
                        return unique.size === networkInterfaces.length;
                    }
                ),
        }),
        onSubmit: (values) => {
            const diskArray = values["disks"]
                .filter(obj => obj.diskType === "DISK")
                .map(obj => ({
                    sizeMb: obj.size,
                    dev: obj.targetDev
                }));

            const newVM = {
                name: values["name"],
                description: values["description"],
                cpus: values["cpus"],
                memory: values["memory"],
                templateSlug: values["templateSlug"],
                rootPasswordPlaintext: values["rootPassword"],
                networkInterfaces: values["networkInterfaces"].map((str) => ({ slug: str })),
                sshAutoAdd: values["sshAutoAdd"],
                disks: diskArray,
                cloudInitUserData: values["cloudInitUserData"],
                autoStart: values.autoStart,
                createStarted: values.createStarted
            };

            (window as any).Intercom('trackEvent', 'create-vm-request');

            handleCreateVirtualMachineRequest(newVM)
        },
    });



    return (    
        <FormikProvider value={addVirtualMachineFormik}>
            <ErrorAlert errorObj={errorObject} />
            <Basics />
            <CPUMemory hypervisor={hypervisor} />
            <Disks hypervisor={hypervisor}/>
            <NetworkInterfaces hypervisor={hypervisor} />
            <Authentication template={template}/>
            <Cloudinit template={template}/>
            <Startup /> 

            <div className="d-flex justify-content-between align-items-center mt-3">
                <Link to={`/vm/create/${hypervisor.slug}`} className="btn btn-link text-decoration-none">
                    <FontAwesomeIcon icon={byPrefixAndName.fal["arrow-left"]} className='me-2' />
                    {t("virtualMachine.create.backToTemplate")}
                </Link>
                <GalliumSubmitButton formik={addVirtualMachineFormik} color="success" className="ms-auto right nexttab" spinner={isMutating}>
                    {t("virtualMachine.create.createButton")}
                </GalliumSubmitButton>
            </div>

            

        </FormikProvider>
    );
};

export default CreateVMForm;