import React from 'react';

import {
    Col,
} from "reactstrap"
import GalliumSizeInput from 'Components/Gallium/GalliumSizeInput';
import { GalliumFormHeader } from 'Components/Gallium/GalliumForms';
import { HostDetail } from 'generated';
import GalliumCpuInput from 'Components/Gallium/GalliumCpuInput';
import { useTranslation } from 'react-i18next';

const CPUMemory = ({ hypervisor }: {hypervisor: HostDetail}) => {
    const {t} = useTranslation()
    return (
        <GalliumFormHeader icon="cpu" title="CPU and Memory">

            <GalliumCpuInput
                name="cpus"
                availableCpus={hypervisor?.availCpus}
            />
            <Col xl={3} lg={4} md={6} className='mb-3'>
                <GalliumSizeInput
                    label={t("virtualMachine.hardwareMemory")}
                    id="memoryInput"
                    name="memory"
                />

            </Col>

        </GalliumFormHeader>
    )
};

export default CPUMemory;
