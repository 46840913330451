import React, { useEffect, useState } from 'react';
import BreadCrumb from 'Components/Common/Breadcrumb';
import { Alert, Card, CardBody,  Container } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { deleteTemplate, getTemplateDetail } from 'store/actions';
import Loader from 'Components/Gallium/Loader';
import DiskView from './Disk'

import DeleteModal from "Components/Common/DeleteModal";
import withRouter from "Components/Common/withRouter";
import StringPretty from 'Components/Gallium/TextHelpers';

const TemplateView = (props) => {
    const dispatch = useDispatch()
    const templateSlug = useParams().slug

    const { template } = useSelector((state) => ({
        // @ts-expect-error
        template: state.Templates.detail[templateSlug] || {}
    }));

    // Delete a Template
    const [deleteModal, setDeleteModal] = useState(false);

    const onClickDelete = () => {
        setDeleteModal(true);
    };

    // TODO Clean up naming in this file and move to SWR
    const handleDeleteSSHKey = () => {
        dispatch(deleteTemplate(templateSlug, props.router.navigate));
        setDeleteModal(false);
    };
    
    // Dispatch Redux action when page loads
    useEffect(() => {
        dispatch(getTemplateDetail(templateSlug));
    }, [dispatch]);

    document.title = "Template View | Gallium";
    return (
        <React.Fragment>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteSSHKey}
                onCloseClick={() => setDeleteModal(false)}
                recordName={template.name || "Unknown"}
            />

            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Template View" pageTitle="Templates" parentLink="/inventory/templates"/>
                    {template && Object.keys(template).length > 0 ? (
                        <React.Fragment>
                            <Card>
                                <CardBody className="pb-0">
                                    {template.state === "DRAFT" ? (
                                        <Alert color="info">
                                            <strong>Draft Template</strong>
                                            <p>This template has not been finalised. Please finish uploading disks and finalise the template before use.</p>
                                            <Link to={"/inventory/templates/create?template=" + templateSlug}>Click here to Continue</Link>
                                        </Alert>
                                    ):(null)}
                                    <div className="d-flex">
                                        <div className="flex-grow-1">
                                            <h4>{template.name}</h4>
                                            <div className="hstack gap-3 flex-wrap">
                                                <div>
                                                    {template.galliumProvided ? (
                                                        <span className="text-info d-block">
                                        Gallium Template
                                                        </span>
                                                    ):(
                                                        <span className="text-success d-block">
                                        Custom Template
                                                        </span>
                                                    )}
                                                </div>
                                                <div className="vr"></div>
                                                <div className="text-muted">
                                    State :{" "}
                                                    <span className="text-body fw-medium">
                                                        <StringPretty string={template.state} />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-shrink-0">
                                            {template.galliumProvided ? (null):(
                                                <div>
                                                    <a
                                                        onClick={() => (onClickDelete())}
                                                        id="TooltipTop"
                                                        className="btn btn-soft-danger"
                                                    >
                                                        <i className="ri-delete-bin-line align-bottom"></i>
                                                    </a>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="mt-4 text-muted">
                                        <h5 className="fs-14">Description :</h5>
                                        <p>
                                            {template.description || "No Description Available"}
                                        </p>
                                    </div>

                                    <div className="table-responsive mb-2">
                                        <h5 className="fs-14">Settings :</h5>
                                        <table className="table">
                                            <tbody>
                                                <tr>
                                                    <th scope="row" style={{ width: "200px" }}>
                                    OS Type
                                                    </th>
                                                    <td><StringPretty string={template.osType} /></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Minimum Memory</th>
                                                    <td>{template.minMemoryMb > 0 ? (template.minMemoryMb + " MB"):("No Minimum")}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Cloud-init Enabled</th>
                                                    <td>{template.supportsCloudInit ? ("Yes"):("No")}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                        
                                    <div>
                                        <h5 className="fs-14">Disks:</h5>
                                    </div>
                                    {template.disks.length > 0 ? 
                                        (template.disks.map((disk, index) =>(
                                            <DiskView disk={disk} key={index} />
                                        ))
                                        ):(
                                            <p className="text-muted">Template has no disks</p> 
                                        )}
                                </CardBody>
                            </Card>
                            <div className="d-flex align-items-start gap-3 mt-4">
                                <Link to="/inventory/templates">
                                    <button className="btn btn-link text-decoration-none btn-label">
                                        <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>{" "}
                                Back to Templates
                                    </button>
                                </Link>    
                            </div>
                        </React.Fragment>
                    ):(<Loader />)}
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withRouter(TemplateView);

