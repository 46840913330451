import React from 'react';

import {
    Card,
    CardHeader,
    CardBody,
    Table,
} from "reactstrap"

import Loader from 'Components/Gallium/Loader';
import StatusBadge from 'Components/Gallium/StatusBadge';
import FormatCommand from 'Components/Gallium/FormatCommand';
import { useGetListCommands } from 'GalliumAPIHooks/Commands/CommandsHooks';
import ErrorNotice from 'Components/Gallium/ErrorNotice';
import { ApiCmdStatus } from 'generated';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import GalliumThirdColumn from 'Components/Gallium/GalliumThirdColumn';


type VirtualMachineTasksProps = {
  vmSlug: string;
};

const VirtualMachineTasksBadge: React.FC<VirtualMachineTasksProps> = ({ vmSlug }) => {
    const { data, error, isLoading } = useGetListCommands();

    const vmCommands = data?.commands?.filter(command => command.hostSlug === vmSlug);
    // Compute the number of in-progress commands, handling cases where data or commands may not be loaded yet
    const numInProgressCommands = vmCommands?.filter(command => command.status === ApiCmdStatus.IN_PROGRESS).length ?? 0;

    if (isLoading || error || numInProgressCommands === 0) {
        // Return null to render nothing if loading, error, or no commands in progress
        return null;
    }

    // Render the badge only if there are in-progress commands
    return (
        <span className="badge bg-primary text-white rounded p-1">{numInProgressCommands}</span>
    );
};

const VirtualMachineTasks = () => {
    const { t } = useTranslation()
    const {vmSlug} = useParams  ()
    const { data, error, isLoading } = useGetListCommands();

    const vmCommands = data?.commands?.filter(command => command.vmSlug === vmSlug)

    return (
        <GalliumThirdColumn heading={t("virtualMachine.nav.TASKS.title")} icon={t("virtualMachine.nav.TASKS.icon")}>
            {isLoading ? (<Loader />) : error ? (<ErrorNotice />) : (
                <Card>
                    <CardHeader className=''>
                        <h4 className="card-title mb-0">{t("tasks.allTasks")}</h4>
                    </CardHeader>
                    <CardBody >
                        <div className="table-responsive table-card">
                            <Table className="align-middle table-nowrap mb-0">
                                <thead className="table-light">
                                    <tr>
                                        <th scope="col">{t("tasks.type")}</th>
                                        <th scope="col">{t("tasks.time")}</th>
                                        <th scope="col">{t("tasks.user")}</th>
                                        <th scope="col">{t("tasks.status")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {vmCommands.map((command, key) => (
                                        <tr key={key}>
                                            <td>
                                                <FormatCommand command={command}/> 
                                                <p className='text-muted m-0 small'>{command.progressMessage}</p>
                                                {command.vmSlug ? (
                                                    <Link to={`/vm/${command.vmSlug}`} className="text-muted fs-12 mb-0"><i className="mdi mdi-subdirectory-arrow-right"></i> {command.entityName}</Link>
                                                ):(null)}
                                            </td>
                                            <td>{command.issuedAtDisplay}</td>
                                            <td>{command.requestingUser?.name || "System"}</td>
                                            <td><StatusBadge status={command.status} /></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                </Card>    
            )}
        </GalliumThirdColumn>
    )
};

export {VirtualMachineTasks, VirtualMachineTasksBadge}