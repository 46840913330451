import React from 'react';

import Loader from 'Components/Gallium/Loader';
import ErrorNotice from 'Components/Gallium/ErrorNotice';
import { useGetBackupSetDetails } from 'GalliumAPIHooks/Backup/BackupHooks';
import BackupSetTaskListComponent from './BackupSetTaskList';
import { VirtualMachineDetail } from 'generated';

interface VirtualMachineBackupProfileProps {
    profileSlug: string
    setSlug: string
    virtualMachine: VirtualMachineDetail
}

const VirtualMachineBackupProfile: React.FC<VirtualMachineBackupProfileProps> = ({profileSlug, setSlug, virtualMachine}) => {
    const {data: backupSet, isLoading, error} = useGetBackupSetDetails(profileSlug, setSlug)
    return (
        <React.Fragment>
            {isLoading ? (<Loader />) : error ? (<ErrorNotice />) : (
                    <BackupSetTaskListComponent 
                        profileSlug={profileSlug} 
                        setSlug={setSlug} 
                        backupTasks={backupSet.backupTasks} 
                        virtualMachine={virtualMachine}
                    />
            )}
        </React.Fragment>
    )
}

export default VirtualMachineBackupProfile;