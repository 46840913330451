import React, { useEffect } from 'react';

import { useSelector, useDispatch } from "react-redux";

import withRouter from "../../../../Components/Common/withRouter";

import * as Yup from "yup";

import { useFormik, FormikProvider } from "formik";

import TemplateBasics from './Basics';
import OSType from './OSType';
import { createTemplate, resetTemplateFlags } from 'store/actions';
import TemplateSettings from './Settings';
import ErrorAlert from 'Components/Gallium/ErrorHelper';

const CreateTemplateStepOne = (props) => {
    const dispatch = useDispatch();

    const { error } = useSelector((state) => ({
        // @ts-expect-error
        error: state.Templates.error?.error?.response?.data || undefined
    }));

    // Ensure Redux state contains no errors not related to this render
    useEffect(() => {
        // Effect logic (runs after the component mounts)
        dispatch(resetTemplateFlags())
        // Cleanup logic (runs after the component unmounts)
        return () => {
            dispatch(resetTemplateFlags())
        };
    }, []);

    // Formik
    const createTemplateFormik = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            name: "",
            description: "",
            osType: "LINUX",
            memory: "1",
            memoryMultiplier: "1024",
            // supportsCloudInit: false
        },

        validationSchema: Yup.object({
            name: Yup.string().required("Please Enter Name"),
        }),

        onSubmit: (values) => {
            const newTemplate = {
                name: values["name"],
                description: values["description"],
                osType: values["osType"],
                // TODO Replace with Gallium Size Component
                // @ts-expect-error
                minMemoryMb: values["memory"] * values["memoryMultiplier"],
                // supportsCloudInit: values["supportsCloudInit"]
            };
            // @ts-expect-error
            window.Intercom('trackEvent', 'create-template-request');

            // save new template
            dispatch(createTemplate(newTemplate, props.router.navigate));
        },
    });

    // Watch for errors returned from the API and pass them back to Formik to display to the user
    useEffect(() => {
        if (error) {
            createTemplateFormik.setErrors(error.errors)    
        }
    }, [error])

    return (    
        <React.Fragment>
            <FormikProvider value={createTemplateFormik}>
                <ErrorAlert errorObj={error} />

                <TemplateBasics createTemplateFormik={createTemplateFormik}/>
                <OSType createTemplateFormik={createTemplateFormik}/>
                <TemplateSettings createTemplateFormik={createTemplateFormik}/>

                <div className="d-flex align-items-start gap-3 mt-3">
                    <button
                        type="button"
                        className="btn btn-primary btn-label right ms-auto nexttab"
                        onClick={() => {
                            createTemplateFormik.handleSubmit()
                        }}
                    >
                        <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                    Configure Disks
                    </button>
                </div>

            </FormikProvider>
        </React.Fragment>
    );
};

export default withRouter(CreateTemplateStepOne);