import DeleteModal from "Components/Common/DeleteModal";
import { useDeleteBackupSet } from "GalliumAPIHooks/Backup/BackupHooks";
import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";

const Actions = (cell) => {
    const profileSlug = useParams().profileSlug

    const { trigger, isMutating } = useDeleteBackupSet(profileSlug, cell.getValue())

    const [showDeleteModal, setShowDeleteModal] = useState<boolean | undefined>(false);
    
    const handleDelete = () => {
        trigger();
        setShowDeleteModal(false);
    };

    return (
        <React.Fragment>
            <DeleteModal    
                show={showDeleteModal}
                onDeleteClick={handleDelete}
                onCloseClick={() => setShowDeleteModal(false)}
                recordName={cell.row.original.slug}
                isWorking={isMutating}
            />
            <Link to="#" className="remove-item-btn" onClick={() => {setShowDeleteModal(true) }}>
                <span className='text-muted text'>
                    <i className="ri-delete-bin-5-line"></i>
                </span>
            </Link>
        </React.Fragment>
    );
};


export {Actions}