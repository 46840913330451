import React from 'react';

import {
    Col,
    Label,
} from "reactstrap"
import { Field } from 'formik';
import { GalliumFormHeader } from 'Components/Gallium/GalliumForms';

const BackupProfileTarget = ({ hypervisors }) => {

    return (
        <React.Fragment>
            <GalliumFormHeader icon="target" title="Target">
                <div className="mt-0 mb-3">
                    <Label className="form-label" htmlFor="hypervisorField">Target Hypervisor</Label>

                    <Col md={6}>
                        <Field
                            id="hypervisorField"
                            name="targetHostSlug"
                            as="select"
                            className="form-select mb-3"
                        >
                            <option value="">Please Select Hypervisor</option>
                            {hypervisors ? (hypervisors?.map((item) =>(
                                <option value={item.slug} key={item.slug}>{item.name}</option>
                            ))):(null)}
                        </Field>
                    </Col>
                </div>
            </GalliumFormHeader>
        </React.Fragment>
    )
};

export default BackupProfileTarget;
