import React, { useState } from 'react';
import { useField, useFormikContext } from 'formik';
import { FormFeedback, Label } from 'reactstrap'; // Assuming you use Reactstrap for feedback

interface GalliumSizeInputProps {
    name: string;
    id?: string;
    label?: string;
    disabled?: boolean;
}

const GalliumSizeInput: React.FC<GalliumSizeInputProps> = ({ name, id = "SizeInput", label, disabled = false, ...props }) => {
    const { setFieldValue } = useFormikContext();
    const [field, meta] = useField(name);
    const [multiplier, setMultiplier] = useState<number>(1024);

    const handleSizeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const size = parseFloat(e.target.value) || 0;
        setFieldValue(name, size * multiplier);
    };

    const handleMultiplierChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newMultiplier = parseFloat(e.target.value);
        setMultiplier(newMultiplier);
        setFieldValue(name, (field.value as number) * newMultiplier);
    };

    return (
        <React.Fragment>{label && <Label className="form-label" htmlFor={id}>{label}</Label>}
            <div className="input-group">
                
                <input
                    type="number"
                    className={`form-control ${meta.touched && meta.error ? 'is-invalid' : ''}`}
                    placeholder="Size"
                    id={id}
                    onChange={handleSizeChange}
                    disabled={disabled}
                    value={field.value ? field.value / multiplier : ''}
                    {...props}
                />

                <select
                    className="input-group-text"
                    value={multiplier}
                    onChange={handleMultiplierChange}
                >
                    <option value={1}>MB</option>
                    <option value={1024}>GB</option>
                    <option value={1048576}>TB</option>
                </select>

                {meta.touched && meta.error && (
                    <FormFeedback type="invalid">{meta.error}</FormFeedback>
                )}
            </div>
        </React.Fragment>
    );
};

export default GalliumSizeInput;
