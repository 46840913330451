/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BackupProfileResponse } from '../models/BackupProfileResponse';
import type { BackupSetDetailResponse } from '../models/BackupSetDetailResponse';
import type { GalliumApiMessageResponse } from '../models/GalliumApiMessageResponse';
import type { GalliumApiSuccessResponse } from '../models/GalliumApiSuccessResponse';
import type { ListBackupProfilesResponse } from '../models/ListBackupProfilesResponse';
import type { NewBackupProfileRequest } from '../models/NewBackupProfileRequest';
import type { NewBackupProfileResponse } from '../models/NewBackupProfileResponse';
import type { NewBackupSetRequest } from '../models/NewBackupSetRequest';
import type { RestoreBackupRequest } from '../models/RestoreBackupRequest';
import type { UpdateBackupProfileRequest } from '../models/UpdateBackupProfileRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BackupService {

    /**
     * Get all backup profiles
     * @param targetHostSlug Filter for profiles that are backing up to a particular host
     * @param vmSlug Filter for profiles that are backing up a particular VM.
     * @returns ListBackupProfilesResponse OK
     * @throws ApiError
     */
    public static listBackupProfiles(
        targetHostSlug?: string,
        vmSlug?: string,
    ): CancelablePromise<ListBackupProfilesResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/backup',
            query: {
                'targetHostSlug': targetHostSlug,
                'vmSlug': vmSlug,
            },
            errors: {
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Create a new backup profile
     * @param requestBody
     * @returns NewBackupProfileResponse OK
     * @throws ApiError
     */
    public static newBackupProfile(
        requestBody: NewBackupProfileRequest,
    ): CancelablePromise<NewBackupProfileResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/backup/new',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Restore a backed up VM onto the backup host
     * @param backupTaskSlug
     * @param requestBody
     * @returns GalliumApiSuccessResponse OK
     * @throws ApiError
     */
    public static restoreBackup(
        backupTaskSlug: string,
        requestBody: RestoreBackupRequest,
    ): CancelablePromise<GalliumApiSuccessResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/backup/restore/{backupTaskSlug}',
            path: {
                'backupTaskSlug': backupTaskSlug,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Update a backup profile
     * @param profileSlug
     * @param requestBody
     * @returns BackupProfileResponse OK
     * @throws ApiError
     */
    public static updateBackupProfile(
        profileSlug: string,
        requestBody: UpdateBackupProfileRequest,
    ): CancelablePromise<BackupProfileResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/backup/{profileSlug}',
            path: {
                'profileSlug': profileSlug,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Run a backup profile now
     * @param profileSlug
     * @param srcHostSlug Only run backups for VMs from a specific host
     * @param srcVmSlug Only run backup for a specific VM
     * @param setSlug Only run a specific backup set
     * @returns GalliumApiMessageResponse OK
     * @throws ApiError
     */
    public static runBackupProfileNow(
        profileSlug: string,
        srcHostSlug?: string,
        srcVmSlug?: string,
        setSlug?: string,
    ): CancelablePromise<GalliumApiMessageResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/backup/{profileSlug}/run',
            path: {
                'profileSlug': profileSlug,
            },
            query: {
                'srcHostSlug': srcHostSlug,
                'srcVmSlug': srcVmSlug,
                'setSlug': setSlug,
            },
            errors: {
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Create a new backup set on a profile
     * @param profileSlug
     * @param requestBody
     * @returns BackupSetDetailResponse OK
     * @throws ApiError
     */
    public static newBackupSet(
        profileSlug: string,
        requestBody: NewBackupSetRequest,
    ): CancelablePromise<BackupSetDetailResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/backup/{profileSlug}/sets/new',
            path: {
                'profileSlug': profileSlug,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Get a specific backup set
     * @param profileSlug
     * @param setSlug
     * @returns BackupSetDetailResponse OK
     * @throws ApiError
     */
    public static getBackupSetDetails(
        profileSlug: string,
        setSlug: string,
    ): CancelablePromise<BackupSetDetailResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/backup/{profileSlug}/sets/{setSlug}',
            path: {
                'profileSlug': profileSlug,
                'setSlug': setSlug,
            },
            errors: {
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Delete a backup set. This does not delete the underlying backups.
     * @param profileSlug
     * @param setSlug
     * @returns GalliumApiMessageResponse OK
     * @throws ApiError
     */
    public static deleteBackupSet(
        profileSlug: string,
        setSlug: string,
    ): CancelablePromise<GalliumApiMessageResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/backup/{profileSlug}/sets/{setSlug}',
            path: {
                'profileSlug': profileSlug,
                'setSlug': setSlug,
            },
            errors: {
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

}
