import React from 'react';

import {
    Card,
    CardHeader,
    CardBody,
    Col,
    Table,
} from "reactstrap"

import Loader from 'Components/Gallium/Loader';
import StatusBadge from 'Components/Gallium/StatusBadge';
import FormatCommand from 'Components/Gallium/FormatCommand';
import { useGetListCommands } from 'GalliumAPIHooks/Commands/CommandsHooks';
import ErrorNotice from 'Components/Gallium/ErrorNotice';
import { ApiCmdStatus, HostDetail } from 'generated';
import GalliumPageColumn from 'Components/Gallium/GalliumPageColumn';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


type HypervisorTasksProps = {
  hypervisorSlug: string;
};

const HypervisorTasksBadge: React.FC<HypervisorTasksProps> = ({ hypervisorSlug }) => {
    const { data, error, isLoading } = useGetListCommands();

    const hostCommands = data?.commands?.filter(command => command.hostSlug === hypervisorSlug);
    // Compute the number of in-progress commands, handling cases where data or commands may not be loaded yet
    const numInProgressCommands = hostCommands?.filter(command => command.status === ApiCmdStatus.IN_PROGRESS).length ?? 0;

    if (isLoading || error || numInProgressCommands === 0) {
        // Return null to render nothing if loading, error, or no commands in progress
        return null;
    }

    // Render the badge only if there are in-progress commands
    return (
        <span className="badge bg-primary text-white rounded p-1">{numInProgressCommands}</span>
    );
};

const HypervisorTasks: React.FC<HypervisorTasksProps> = ({ hypervisorSlug }) => {
    const { t } = useTranslation()
    const { data, error, isLoading } = useGetListCommands();

    const hostCommands = data?.commands?.filter(command => command.hostSlug === hypervisorSlug)

    return (
        <GalliumPageColumn>
            {isLoading ? (<Loader />) : error ? (<ErrorNotice />) : (
                <Card>
                    <CardHeader className=''>
                        <h4 className="card-title mb-0">{t("tasks.allTasks")}</h4>
                    </CardHeader>
                    <CardBody >
                        <div className="table-responsive table-card">
                            <Table className="align-middle table-nowrap mb-0">
                                <thead className="table-light">
                                    <tr>
                                        <th scope="col">{t("tasks.type")}</th>
                                        <th scope="col">{t("tasks.time")}</th>
                                        <th scope="col">{t("tasks.user")}</th>
                                        <th scope="col">{t("tasks.status")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {hostCommands.map((command, key) => (
                                        <tr key={key}>
                                            <td>
                                                <FormatCommand command={command}/> 
                                                <p className='text-muted m-0 small'>{command.progressMessage}</p>
                                                {command.vmSlug ? (
                                                    <Link to={`/vm/${command.vmSlug}`} className="text-muted fs-12 mb-0"><i className="mdi mdi-subdirectory-arrow-right"></i> {command.entityName}</Link>
                                                ):(null)}
                                            </td>
                                            <td>{command.issuedAtDisplay}</td>
                                            <td>{command.requestingUser?.name || "System"}</td>
                                            <td><StatusBadge status={command.status} /></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                </Card>    
            )}
        </GalliumPageColumn>
    )
};

export {HypervisorTasks, HypervisorTasksBadge}