import React from 'react';

import {
    Col,
    Label,
    Row,
} from "reactstrap"
import { Field } from 'formik';
import { GalliumFormHeader, GalliumInput } from 'Components/Gallium/GalliumForms';
import { DayOfWeek } from 'generated';

const BackupProfileSettings = () => {

    // 1. Hours: array from 0 to 23
    const hours: number[] = Array.from({ length: 24 }, (_, i) => i);

    // 2. Minutes: array from 0 to 55 in 5-minute intervals
    const minutes: number[] = Array.from({ length: 12 }, (_, i) => i * 5);

    // 3. Timezones: array of objects with title and minutes offset
    interface Timezone {
    title: string;
    minutesOffset: number;
    }

    const timezones: Timezone[] = Array.from({ length: 25 }, (_, i) => {
    const offset = i - 12; // to get GMT -12 to GMT +12
    return {
        title: `GMT ${offset >= 0 ? "+" : ""}${offset}`,
        minutesOffset: offset * 60, // convert to minutes
    };
});

    return (
        <React.Fragment>
            <GalliumFormHeader icon="info" title="Key Settings">
                <Col md={4} className="mb-3">
                    <Field
                        name="name"
                        id="name"
                        className="form-control"
                        placeholder="Enter a friendly name"
                        type="text"
                        label="Name"
                        component={GalliumInput}
                    />
                </Col>
            </GalliumFormHeader>
            <GalliumFormHeader icon="info" title="Schedule">
                

                <div className="mt-0 mb-3">
                    <Label className="form-label" htmlFor="hypervisorField">Full Backup Day</Label>

                    <Col md={6}>
                        <Field
                            id="fullBackupDay"
                            name="fullBackupDay"
                            as="select"
                            className="form-select mb-3"
                        >
                            {Object.values(DayOfWeek).map((item) => (
                                <option value={item} key={item}>
                                    {item}
                                </option>
                            ))}
                        </Field>
                    </Col>
                </div>
                <Row>
                    <Col md={4} className="mb-3">
                        <Label className="form-label" htmlFor="backupTime[hour]">Hour</Label>
                        <Field
                            name="backupTime[hour]"
                            id="backupTime[hour]"
                            className="form-select"
                            as="select"
                        >
                            {hours.map((hour)=>(
                                <option value={hour} key={`hours${hour}`}>
                                    {hour}
                                </option>
                            ))}
                        </Field>
                    </Col>
                    <Col md={4} className="mb-3">
                        <Label className="form-label" htmlFor="backupTime[minute]">Minute</Label>
                        <Field
                            name="backupTime[minute]"
                            id="backupTime[minute]"
                            className="form-select"
                            as="select"
                        >
                            {minutes.map((minute)=>(
                                <option value={minute} key={`minute${minute}`}>
                                    {minute}
                                </option>
                            ))}
                        </Field>
                    </Col>
                    <Col md={4} className="mb-3">
                        <Label className="form-label" htmlFor="backupTime[timezoneOffsetMinutes]">Offset</Label>
                        <Field
                            name="backupTime[timezoneOffsetMinutes]"
                            id="backupTime[timezoneOffsetMinutes]"
                            className="form-select"
                            as="select"
                        >
                            {timezones.map((timezone)=>(
                                <option value={timezone.minutesOffset} key={`timezones${timezone.minutesOffset}`}>
                                    {timezone.title}
                                </option>
                            ))}
                        </Field>
                    </Col>
                </Row>
            </GalliumFormHeader>
            <GalliumFormHeader icon="info" title="Retention">
                

                <Col md={4} className="mb-3">
                    <Field
                        name="expiryDays"
                        id="expiryDays"
                        className="form-control"
                        placeholder="00"
                        type="number"
                        label="Retention Days"
                        component={GalliumInput}
                    />
                </Col>

            </GalliumFormHeader>
        </React.Fragment>
    )
};

export default BackupProfileSettings;
