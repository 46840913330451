import React from 'react';

import {
    Button,
} from "reactstrap"

import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-d2e55409b9/icons';
import { useRunBackupProfileNow } from 'GalliumAPIHooks/Backup/BackupHooks';
import { toast } from 'react-toastify';
import { GalliumSpinnerButton } from 'Components/Gallium/GalliumForms';
import { VirtualMachineDetail } from 'generated';

interface RunBackupNowButtonProps {
    setSlug: string
    profileSlug: string
    virtualMachine: VirtualMachineDetail
}

const RunBackupNowButton: React.FC<RunBackupNowButtonProps> = ({ profileSlug, setSlug, virtualMachine }) => {
    const { t } = useTranslation()
    const { trigger, isMutating } = useRunBackupProfileNow(profileSlug, setSlug)


    const handleFail = () => {
        toast.error(t('virtualMachine.backup.RunBackupNowFailed'))
    }

    const handleSuccess = () => {
        toast.success(t('virtualMachine.backup.RunBackupNowSuccess'))
    }

    const handleButtonClick = () => {
        const options = {
            onError() {
                handleFail()
            },
            onSuccess() {
                handleSuccess()
            }
        }
        trigger(null, options);
    }

    if (!virtualMachine.availableActions.changePowerState) return (
        <Button color="soft-primary" disabled className='mb-0' onClick={(handleButtonClick)}>
            <FontAwesomeIcon icon={byPrefixAndName.fak["light-boxes-stacked-circle-play"]} className='ms-1 me-2'/>
            {t('virtualMachine.backup.RunBackupNowButton')}
        </Button>
    )   


    if (isMutating) return (
        <GalliumSpinnerButton color="primary" />
    )

    return (
        <Button color="soft-primary" className='mb-0' onClick={(handleButtonClick)}>
            <FontAwesomeIcon icon={byPrefixAndName.fak["light-boxes-stacked-circle-play"]} className='ms-1 me-2'/>
            {t('virtualMachine.backup.RunBackupNowButton')}
        </Button>
    )
};

export default RunBackupNowButton;