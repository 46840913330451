import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useSelector, useDispatch } from "react-redux";

import withRouter from "../../../../Components/Common/withRouter"

import {
    Row,
    Col,
    Card,
    CardBody,
    Input,
    Button,
    FormFeedback
} from "reactstrap";

import * as Yup from "yup";

import { useFormik, FormikProvider, Field } from "formik";

import { getTemplateDetail, resetTemplateFlags, createTemplateDisk, updateTemplate } from 'store/actions';
import TemplateDisk from './Disk';
import ErrorAlert from 'Components/Gallium/ErrorHelper';

const CreateTemplateStepTwo = (props) => {

    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useDispatch();
    
    const templateSlug = searchParams.get("template")

    const { template, error } = useSelector((state) => ({
        // @ts-expect-error
        template: state.Templates.detail[templateSlug] || {},
        // @ts-expect-error
        error: state.Templates.error?.error?.response?.data || undefined
    }));

    // Setup and Cleanup
    useEffect(() => {
        // Effect logic (runs after the component mounts)
        if (searchParams.get("template") !== null) {
            dispatch(getTemplateDetail(templateSlug))
        }
        // Cleanup logic (runs after the component unmounts)
        return () => {
        // nothing to do
        };
    }, []);

    const [draftBlankInProgress, setDraftBlankInProgress] = useState(false);

    const handleDiskAdd = (input) => {
        if (input === "upload") {
            createUploadDisk();
        } else if (input === "blank") {
            createBlankDiskDraft();
        } else if (input === "optical") {
            createOpticalDisk();
        }
    };

    const createUploadDisk = () => {
        const uploadDisk = {
            "blank": false,
            "diskType": "DISK"
        }
        dispatch(createTemplateDisk(templateSlug, uploadDisk))
    };

    const createBlankDiskDraft = () => {
        setDraftBlankInProgress(true)
    };

    // We'll call this from formik to clean up the page once the blank disk is created
    const completeBlankDiskDraft = () => {
        setDraftBlankInProgress(false)
    };

    const createOpticalDisk = () => {
        const uploadDisk = {
            "blank": false,
            "diskType": "CDROM"
        }
        dispatch(createTemplateDisk(templateSlug, uploadDisk))
    };

    const finaliseTemplate = () => {
        dispatch(updateTemplate(templateSlug, {"state": "READY"}, props.router.navigate))
    }

    // Use a Formik for blank disk because validation may be required in the future
    const addBlankDiskFormik = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            volumeSize: "8",
            volumeMultiplier: "1024",
        },
        validationSchema: Yup.object().shape({
            volumeSize: Yup.number()
                .required('Required')
                .test('is-product-over', 'Disk size cannot exceed 10TB', 
                    function(value) {
                        const { volumeMultiplier } = this.parent;
                        return value * volumeMultiplier <= 10485761;
                    }),
            volumeMultiplier: Yup.number()
                .required('Required')
                .test('is-product-over', 'Disk size cannot exceed 10TB', 
                    function(value) {
                        const { volumeSize } = this.parent;
                        return value * volumeSize <= 10485761;
                    }),
        }),
        onSubmit: (values) => {
            const blankDisk = {
                blank: true,
                // TODO Replace with Size Input
                // @ts-expect-error
                sizeMb: values["volumeSize"] * values["volumeMultiplier"],
                templateSlug: values["templateSlug"],
                diskType: "DISK"
            };
            // Create new blank disk in template
            dispatch(createTemplateDisk(templateSlug, blankDisk));
            completeBlankDiskDraft()
        },
    });

    const DiskArray = template.disks?.map((item, index) => {
        return (
            <TemplateDisk disk={item} key={item.slug} />
        );
    });

    // Ensure Redux state contains no errors not related to this render
    useEffect(() => {
        // Effect logic (runs after the component mounts)
        dispatch(resetTemplateFlags())
        // Cleanup logic (runs after the component unmounts)
        return () => {
            dispatch(resetTemplateFlags())
        };
    }, []);

    return (    
        <React.Fragment>
            <div>
                <h5 className="mb-1">Configure Disks</h5>
                <p className="text-muted mb-4">
                    Add Disks to this Template by uploading images or creating them from scratch. Disks can be uploaded in QCOW2 or ISO format. Once deployed, disks will be mounted in the order they are added to the template.   
                </p>
                <ErrorAlert errorObj={error} />
            </div>
            <Row>
                <Col>
                    {DiskArray}
                </Col>
            </Row>
            <Row>
                <Col>
                    {draftBlankInProgress ? (
                        <Card>
                            <FormikProvider value={addBlankDiskFormik}>
                                <CardBody>
                                    <Row className='g-3'>
                                        <Col md={6}>
                                            <div className='input-group'>
                                                <Input
                                                    name="volumeSize"
                                                    id="volumeSize"
                                                    className="form-control"
                                                    placeholder="Minimum Size"
                                                    type="text"
                                                    validate={{
                                                        required: { value: true },
                                                    }}
                                                    onChange={addBlankDiskFormik.handleChange}
                                                    onBlur={addBlankDiskFormik.handleBlur}
                                                    value={addBlankDiskFormik.values.volumeSize || ""}
                                                    invalid={
                                                        addBlankDiskFormik.touched.volumeSize && addBlankDiskFormik.errors.volumeSize ? true : false
                                                    }
                                                />
                                                <Field
                                                    id="volumeMultiplier"
                                                    name="volumeMultiplier"
                                                    as="select"
                                                    className="input-group-text"
                                                    onChange={addBlankDiskFormik.handleChange}
                                                    onBlur={addBlankDiskFormik.handleBlur}
                                                >
                                                    <option value="0">MB</option>
                                                    <option value="1024">GB</option>
                                                    <option value="1048576">TB</option>
                                                </Field>
                                                {addBlankDiskFormik.touched.volumeSize && addBlankDiskFormik.errors.volumeSize ? (
                                                    <FormFeedback type="invalid">{addBlankDiskFormik.errors.volumeSize}</FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>
                                        <Col md={3}>
                                            <Button color="primary" className="w-100" onClick={() => addBlankDiskFormik.handleSubmit()}>Add Blank Disk</Button>
                                        </Col> 
                                        <Col md={3}>
                                            <Button className="btn-soft-secondary w-100" onClick={() => setDraftBlankInProgress(false)}>Cancel</Button>    
                                        </Col>
                                    </Row>
                                </CardBody>
                            </FormikProvider>
                        </Card>
                    ):(
                        <Card className="border border-dashed">
                            <CardBody>
                                <Row>
                                    <Col md={4}>
                                        <Button color="primary" className="btn-label w-100" onClick={() => handleDiskAdd("blank")}><i className="ri-hard-drive-line label-icon align-middle"></i> Add Blank Disk</Button>
                                    </Col>
                                    <Col md={4}>
                                        <Button color="primary" className="btn-label  w-100" onClick={() => handleDiskAdd("upload")}><i className="ri-uninstall-line label-icon align-middle"></i> Upload Existing Disk</Button>
                                    </Col>
                                    <Col md={4}>    
                                        <Button color="primary" className="btn-label  w-100" onClick={() => handleDiskAdd("optical")}><i className="ri-album-line label-icon align-middle"></i>  Upload ISO</Button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    )}
                </Col>
            </Row>

            <div className="d-flex align-items-start gap-3">
                <button
                    type="button"
                    className="btn btn-success btn-label right ms-auto nexttab"
                    onClick={() => {
                        finaliseTemplate()
                    }}
                >
                    <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                    Finalise Template
                </button>
            </div>
        </React.Fragment>
    );
};

export default withRouter(CreateTemplateStepTwo);