import React, { SetStateAction, useState } from 'react';

import * as Yup from "yup";

import { useFormik, FormikProvider } from "formik";

import {
    Col,
    Row,
    Card,
    CardBody,
    Button,
    Tooltip,
} from "reactstrap"

import { ConfigureVmDiskAction, GalliumApiErrorResponse, HostDetail, ReconfigureVmRequest, VirtualMachineDetail } from 'generated';

import { useModifyVirtualMachineConfig } from 'GalliumAPIHooks/VirtualMachine/VirtualMachineHooks';

import { useGetHost } from 'GalliumAPIHooks/Host/HostHooks';
import Loader from 'Components/Gallium/Loader';
import ErrorNotice from 'Components/Gallium/ErrorNotice';
import { GalliumSubmitButton } from 'Components/Gallium/GalliumForms';
import ErrorAlert from 'Components/Gallium/ErrorHelper';
import GalliumSizeInput from 'Components/Gallium/GalliumSizeInput';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-d2e55409b9/icons'
import AttachDiskButton from './AttachDiskButton';


interface AddDiskFormProps {
    virtualMachine: VirtualMachineDetail;
    hypervisor: HostDetail;
    setDiskAddForm: React.Dispatch<SetStateAction<boolean>>
}

const AddDiskForm: React.FC<AddDiskFormProps> = ({ virtualMachine, hypervisor, setDiskAddForm }) => {
    const { t } = useTranslation(['translation'])

    const {trigger, isMutating} = useModifyVirtualMachineConfig(virtualMachine.slug)

    const [errorObject, setErrorObject] = useState(null)

    const handleVirtualMachineChangeFail = (error: GalliumApiErrorResponse) => {
        editVirtualMachineHardwareFormik.setErrors(error.errors || {})
        setErrorObject(error || {})
    }

    const handleVirtualMachineChangeSuccess = () => {
        setDiskAddForm(false)
    }

    const handleVirtualMachineChangeRequest = (values: ReconfigureVmRequest) => {
        const options = {
            onError(error: GalliumApiErrorResponse) {
                handleVirtualMachineChangeFail(error)
            },
            onSuccess() {
                handleVirtualMachineChangeSuccess()
            }
        }
        setErrorObject(undefined)
        trigger(values, options);
    }

    // Formik
    const editVirtualMachineHardwareFormik = useFormik({
        // enableReinitialize: true,
        initialValues: {
            sizeMb: null
        },
        validationSchema: Yup.object({
            sizeMb: Yup.number()
                .required("Disk size is required")
                .positive("Disk size must be a positive number")
                .integer("Disk size must be an integer")
                .max(hypervisor.availDiskMb, `Disk size cannot exceed ${hypervisor.availDiskMb} MB`)
        }),
        onSubmit: (values) => {
            const requestObj = {
                "disks": [
                    {
                        action: ConfigureVmDiskAction.CREATE,
                        sizeMb: values["sizeMb"]
                    }
                ]
            };
            
            handleVirtualMachineChangeRequest(requestObj)
        },
    });

    return (
        <React.Fragment>
            <FormikProvider value={editVirtualMachineHardwareFormik}>
                <ErrorAlert errorObj={errorObject} />
                    
                <Card>
                    <CardBody>
                        <div className="d-flex flex-column h-100">
                            <div className="d-flex">
                                <div className="flex-grow-1">
                                    <p className="text-muted mb-3">
                                        <FontAwesomeIcon icon={byPrefixAndName.fak["light-hard-drive-circle-plus"]} className='me-2'/>
                                        {t('translation:virtualMachine:diskAdd')}
                                    </p>
                                </div>
                                <div className="flex-shrink-0">
                                    <div className="d-flex gap-1 align-items-center">
                                        <button type="button" className="btn-close float-end fs-11" aria-label="Close" onClick={() => setDiskAddForm(false)}></button>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <Row>
                            <Col md={8}>
                                <GalliumSizeInput
                                    name="sizeMb"
                                />
                            </Col>
                            <Col md={4}>
                                <GalliumSubmitButton formik={editVirtualMachineHardwareFormik} spinner={isMutating} color="success" className="w-100"> 
                                    {t('translation:common:add')}
                                </GalliumSubmitButton>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>

            </FormikProvider>
        </React.Fragment>
    )
};

interface AddDiskProps {
    virtualMachine: VirtualMachineDetail;
}

const AddDisk: React.FC<AddDiskProps> = ({ virtualMachine }) => {
    const { t } = useTranslation(['translation'])

    const { data: hypervisor, error: hypervisorError, isLoading: isHypervisorLoading } = useGetHost(virtualMachine.host.slug);

    const [diskAddForm, setDiskAddForm] = useState(false)

    const [isAddBtntooltipOpen, setAddBtnTooltipOpen] = React.useState(false);

    const [isAttachBtntooltipOpen, setAttachBtnTooltipOpen] = React.useState(false);
    return (
        <React.Fragment>
            <Row className='gy3'>
                <Col md={12} >
                    {diskAddForm ? (
                        isHypervisorLoading ? (<Loader />) : hypervisorError ? (<ErrorNotice />) : (<AddDiskForm virtualMachine={ virtualMachine } hypervisor={hypervisor} setDiskAddForm={setDiskAddForm} />)
                    ):(

                        <div className="text-end pb-3">
                            {virtualMachine.availableActions.modifyHardware? (
                                <React.Fragment>
                                    <Button color="soft-primary" className="mb-0 me-2" onClick={() => setDiskAddForm(true)}>
                                        <FontAwesomeIcon icon={byPrefixAndName.fak["light-hard-drive-circle-plus"]} className='me-2'/>
                                        {t('translation:virtualMachine:diskAdd')}
                                    </Button>
                                    <AttachDiskButton virtualMachine={virtualMachine} />
                                </React.Fragment>
                            ):(
                                <React.Fragment>
                                    <Button color="soft-secondary" className="mb-0" id="addDiskBtn">
                                        <FontAwesomeIcon icon={byPrefixAndName.fak["light-hard-drive-circle-plus"]} className='me-2'/>
                                        {t('translation:virtualMachine:diskAdd')}
                                    </Button>
                                    <Tooltip
                                        isOpen={isAddBtntooltipOpen}
                                        placement="bottom"
                                        target="addDiskBtn"
                                        toggle={() => { setAddBtnTooltipOpen(!isAddBtntooltipOpen) }}>
                                        <div>{t('translation:virtualMachine:modifyVMUnavailable')}</div>
                                    </Tooltip>
                                    <Button color="soft-secondary" className="ms-1" id="attachDiskButon">
                                        <FontAwesomeIcon icon={byPrefixAndName.fak["light-hard-drive-circle-arrow-up"]} className='ms-1 me-2'/>
                                        {t('translation:virtualMachine:diskAttach')}
                                    </Button>
                                    <Tooltip
                                        isOpen={isAttachBtntooltipOpen}
                                        placement="bottom"
                                        target="attachDiskButon"
                                        toggle={() => { setAttachBtnTooltipOpen(!isAttachBtntooltipOpen) }}>
                                        <div>{t('translation:virtualMachine:modifyVMUnavailable')}</div>
                                    </Tooltip>
                                </React.Fragment>
                            )}
                        </div>

                    )}
                </Col>
            </Row>
        </React.Fragment>
    )
};

export default AddDisk;
