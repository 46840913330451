import React from 'react';
import { Link, useParams } from 'react-router-dom';
import {
    Card,
    CardHeader,
    CardBody,
    Col,
    Button,
    Spinner,
    Progress,
    Row,
} from 'reactstrap';
import _ from 'lodash';
import { HostDetail, PoolRole } from 'generated';
import GalliumPageColumn from 'Components/Gallium/GalliumPageColumn';
import { useTranslation } from 'react-i18next';
import HypervisorStoragePoolDisk from './StoragePoolDisk';
import { byPrefixAndName } from '@awesome.me/kit-d2e55409b9/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OrgFlags } from 'Components/Gallium/Flags';

type HypervisorStorageProps = {
  hypervisor: HostDetail;
};

const HypervisorStorage: React.FC<HypervisorStorageProps> = ({ hypervisor }) => {
    const { t } = useTranslation();
    const hypervisorId = useParams().id;
    const storagePools = hypervisor.storagePools;
    console.log(hypervisor.availableActions.backupPoolProvision)

    return (
        <GalliumPageColumn>
            {hypervisor.pendingStorageCommand ? (
                <CardBody className="p-5 mt-5 mb-5 text-center">
                    <Spinner color="primary" type="grow" />
                    <p>{t("hypervisor.storagePool.commandInProgress")}</p>
                </CardBody>
            ) : (
                <React.Fragment>
                    {storagePools.length > 0 || hypervisor.availableActions.backupPoolProvision ? (
                        storagePools.map((pool, index) => {
                            const storageCalculations = {
                                storageAllocatedPercent: (pool.allocatedMb / pool.totalMb) * 100,
                                storageAvailablePercent: (pool.freeMb / pool.totalMb) * 100,
                                storageAllocatedGigabytes: _.round(pool.allocatedMb / 1024, 0),
                                storageAvailableGigabytes: _.round(pool.freeMb / 1024, 0),
                            };

                            return (
                                <React.Fragment>
                                <Card className="mb-2" key={index}>
                                    <CardHeader>
                                        <Row>
                                            <Col>
                                                <h4 className="card-title mb-0">
                                                    {pool.role === PoolRole.BACKUP? (
                                                            <h4 className="card-title mb-0">
                                                                <FontAwesomeIcon icon={byPrefixAndName.fak["light-container-storage-shield"]} className='fa-fw me-2'/>
                                                                {t("hypervisor.storagePool.PoolRole.BACKUP")}
                                                            </h4>
                                                        ):(
                                                            <h4 className="card-title mb-0">
                                                                <FontAwesomeIcon icon={byPrefixAndName.fak["light-container-storage-sparkle"]} className='fa-fw me-2'/>
                                                                {t("hypervisor.storagePool.PoolRole.PRIMARY")}
                                                            </h4>
                                                        ) 
                                                        
                                                    }
                                                </h4>
                                            </Col>
                                            <Col className="text-end">
                                                <span className="badge border border-primary text-primary fs-6">
                                                    {t(`hypervisor.storagePool.DiskGroupType.${pool.groupType}`)}
                                                </span>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                        <Progress multi className="progress-xl mt-2">
                                            <Progress bar color="primary" value={storageCalculations.storageAllocatedPercent} />
                                            <Progress bar color="secondary-subtle" value={storageCalculations.storageAvailablePercent} />
                                        </Progress>

                                        <div className="mt-1 pt-2">
                                            <div className="d-flex mb-2">
                                                <div className="flex-grow-1">
                                                    <p className="text-truncate text-muted fs-14 mb-0">
                                                        <i className="mdi mdi-circle align-middle text-primary me-2"></i>{t("hypervisor.storagePool.allocated")}
                                                    </p>
                                                </div>
                                                <div className="flex-shrink-0">
                                                    <p className="mb-0">{storageCalculations.storageAllocatedGigabytes} GB</p>
                                                </div>
                                            </div>
                                            <div className="d-flex mb-0">
                                                <div className="flex-grow-1">
                                                    <p className="text-truncate text-muted fs-14 mb-0">
                                                        <i className="mdi mdi-circle align-middle text-secondary me-2"></i>{t("hypervisor.storagePool.available")}
                                                    </p>
                                                </div>
                                                <div className="flex-shrink-0">
                                                    <p className="mb-0">{storageCalculations.storageAvailableGigabytes} GB</p>
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                    {pool.groupDetail[0].devices.map((disk, index)=>(
                                        <HypervisorStoragePoolDisk disk={disk} key={disk.id} index={index}/>
                                    ))}
                                </Card>
                                {hypervisor?.availableActions.backupPoolProvision ? (
                                <OrgFlags renderIfExists={true} flag='BACKUP_EARLY_ACCESS'>
                                    <Card>
                                        <CardBody className="p-5 mt-5 mb-5 text-center">
                                            <Link to={`/hypervisors/${hypervisorId}/storage?role=BACKUP`} className="text-dark">
                                                <Button color="primary" className="btn-label">
                                                    <i className="lab las la-plus-square label-icon"></i> {t("hypervisor.storagePool.createPoolButton")}
                                                </Button>
                                            </Link>
                                        </CardBody>
                                    </Card>
                                </OrgFlags>
                                ) : null}
                                </React.Fragment>
                            );
                        })
                    ) : (
                        <CardBody className="p-5 mt-5 mb-5 text-center">
                            {hypervisor?.online ? (
                                <Link to={`/hypervisors/${hypervisorId}/storage`} className="text-dark">
                                    <Button color="primary" className="btn-label">
                                        <i className="lab las la-plus-square label-icon"></i> {t("hypervisor.storagePool.createPoolButton")}
                                    </Button>
                                </Link>
                            ) : null}
                        </CardBody>
                    )}
                </React.Fragment>
            )}
        </GalliumPageColumn>
    );
};

export default HypervisorStorage;
